import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as account from '../communicator/account';
import * as login from '../communicator/login';

class StoreAuth {
    user = {
        id: 0,
        userId: 0,
        email: '',
        isLoggedin: false,
        isAdmin: false,
        isReseller: false
    }

    isLoggedin = false

    constructor(storeRoot) {
        this.storeRoot = storeRoot;
        this.storeGeneral = this.storeRoot.storeGeneral;
        this.storeUser = this.storeRoot.storeUser;

        // makeObservable(this, {
        //     isLoggedin: observable,
        //     firstname: observable,
        //     user: observable
        // });
    }

    async checkVerification(data) {
        let result = true;
       
        this.user.email = data.email;

        try {
            const returnData = await login.checkVerification(data);
            runInAction(() => {
                if (!('error' in returnData.data[0])) {
                    result = true;
                } else {
                    result = false
                }

                return result;
            })
        } catch (error) {
            runInAction(() => {
                //this.user.isLoggedin = false;
            })
        }

        return result;
    }

    async verifyCode(email, formControls) {
        let result;
        const data = {
            email: email,
            code: formControls.code.value
        };

        try {
            const returnData = await login.checkVerificationCode(data);
            runInAction(() => {
                result =  returnData.data[0];
                if (!('error' in returnData.data[0])) {
                    this.user.isLoggedin = true;
                    this.isLoggedin = true;
                } else {
                    this.user.isLoggedin = false;
                    this.isLoggedin = false;
                }

                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.user.isLoggedin = false;
            })
        }

        return result;
    }

    async checkLogin(formControls) {
        let result;

        const data = {
            email: formControls.email.value,
            pw: formControls.password.value
        };
        
        try {
            const returnData = await login.checkLogin(data);
            runInAction(async() => {
                result = returnData.data[0];

                if (!('error' in result)) {
                    this.setLoginState(result);
                    const id = result.id;
                    const email = result.email;
                    this.getAuthData(id, email);
                } else {
                    this.setLogoutState();
                }
                return result;
            })
        } catch (error) {
            runInAction(() => {

            })
        }

        return result;
    }

    async resetPassword(formControls) {
        let result;

        const data = {
            email: this.user.email,
            pw: formControls.password.value
        };
        
        try {
            const returnData = await login.resetPassword(data);
            runInAction(async() => {
                result = returnData.data[0];

                if (!('error' in result)) {
                    this.setLoginState(result);
                    const id = result.id;
                    const email = result.email;
                    //this.getAuthData(id, email);
                } else {
                    this.setLogoutState();
                }
                return result;
            })
        } catch (error) {
            runInAction(() => {

            })
        }

        return result;
    }

    refreshAuth(data) {

    }

    setLoginState = async(data) => {   
        if (data && 'id' in data) {
            runInAction(() => {
                this.isLoggedin = true;
                this.user.isLoggedin = true;
                this.user.id =  data.id;
                this.user.userId = data.id;
                this.user.email = data.email;
                if ('isReseller' in data) {
                    this.user.isReseller = data.isReseller;
                }
                if ('isApproved' in data) {
                    this.user.isApproved = data.isApproved;
                }
            })
        };
        
        const json = JSON.stringify(this.user);
        localStorage.setItem('ABANDA_AUTH', json)

        return true;
    }

    setLogoutState() {
        this.isLoggedin = false;

        this.user.id = 0;
        this.user.userId = 0;
        this.user.email = '';
        this.user.isAdmin = false;
        this.user.isLoggedin = false;
        this.user.isReseller = false; 
        this.user.isApproved = 0;  
        
        this.user.firstname = '';
        this.user.middlename = '';
        this.user.lastname = '';

        const json = JSON.stringify(this.user);
        localStorage.setItem('ABANDA_AUTH', json)

        return false;
    }

    getAuthData = async(id, email) => {
        this.storeGeneral.startLoader();

        try {
            let returnData = await account.getUser(id, email);
            runInAction(() => {
                let data = returnData;
                if (!('error' in data.msg)) {
                    const authData = data.data[0];
                }
            })
        } catch (error) {
            runInAction(() => {
                //this.setLoginState(false);
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
    }

    getAuthDataFromLS = async() => {
        let data;
        if (localStorage.getItem('ABANDA_AUTH')) {
            data = JSON.parse(localStorage.getItem('ABANDA_AUTH'));
            if (data.id !== 0) {
                const isLoggedin = this.setLoginState(data);
            } 
        }
    };
}

decorate(StoreAuth, {
    isLoggedin: observable
})

export default StoreAuth;