import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../../withRouter";

import Product from './product';

const Products = inject("stores") (
    observer (
        class Products extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                
                this.storeProducts = this.props.stores.storeProducts;
                this.storeCart = this.props.stores.storeCart;

                this.state = {
                    products: [],
                    btnClass: '--disabled'
                }
            }

            componentDidMount = async() => {
                const products = await this.storeProducts.getProducts();
                let btnClass = '--disabled';

                if (this.storeCart.cart && this.storeCart.cart.length > 0) {
                    btnClass = '--active';
                }

                this.setState({
                    products: products,
                    btnClass: btnClass
                })
            }

            componentDidUpdate = (prevProps) => {
                let btnClass = '--disabled';
                if (prevProps.nrItems !== this.props.nrItems) {
                    if (this.storeCart.cart && this.storeCart.cart.length > 0) {
                        btnClass = '--active';
                    } else {
                        btnClass = '--disabled';
                    }

                    this.setState({
                        btnClass: btnClass
                    })
                }
            }

            gotoPage = (page) => {
                const url = "/" + page;
                this.props.navigate(url);
            }
        
            render() {
                return (
                    <React.Fragment>
                    <div
                        className = "wrapper-products"
                    >
                        {this.state.products && this.state.products.map((product, i) => (
                            <Product
                                key = {i}
                                product = {product}
                            />
                        ))}
                    </div>
                    <div
                        className = "wrapper-products"
                    >
                        <span
                            className = "wrapper-products__explanation"
                        >
                            You can change the amount in your shopping cart
                        </span>
                    </div>
                    {this.storeCart.cart && this.storeCart.cart.length > 0 &&
                        <div
                            className = "wrapper-products"
                        >
                            <div className="wrapper-content--buttons">
                                <button className={"btn btn--tertiary " + this.state.btnClass} onClick={(e) => this.gotoPage('cart')}>{i18n.t("button.view_cart")}</button>
                            </div>
                        </div>
                    }
                    </React.Fragment>
                )
            }
        }
    )
)

export default withRouter(Products);
