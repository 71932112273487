import { observable, computed, action } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as discounts from '../communicator/discounts';

class StoreDiscount {
    
    constructor() {

    }

    async calcDiscount(product, amount, accountId, isReseller) {
        let discountPerc = 0;
        let overruledPerc = 0;
        let overruledMinAmount = 1;

        const discountsOverruled = await this.getDiscountsOverruled(accountId, product.sku)
        if (discountsOverruled) {
            overruledPerc = discountsOverruled.discountPerc;
            overruledMinAmount = discountsOverruled.discountMinAmount;
        }

        if (overruledPerc > 0) {
            if (isReseller) {
                if (overruledMinAmount < product.discountWholesaleMinAmount) {
                    if (amount < overruledMinAmount) {
                        discountPerc = 0
                    }
                    if (amount >= overruledMinAmount && amount < product.discountWholesaleMinAmount) {
                        discountPerc = overruledPerc;
                    }
                    if (amount >= product.discountWholesaleMinAmount) {
                        if (overruledPerc > product.discountWholesale) {
                            discountPerc = overruledPerc;
                        } else {
                            discountPerc = product.discountWholesale;
                        }
                    }
                } else {
                    if (amount < product.discountWholesaleMinAmount) {
                        discountPerc = 0
                    }
                    if (amount >= product.discountWholesaleMinAmount && amount < overruledMinAmount) {
                        discountPerc = product.discountWholesale;
                    }
                    if (amount >= overruledMinAmount) {
                        if (overruledPerc > product.discountWholesale) {
                            discountPerc = overruledPerc;
                        } else {
                            discountPerc = product.discountWholesale;
                        }
                    }
                }
            } else {
                if (amount < overruledMinAmount) {
                    discountPerc = 0;
                } else {
                    discountPerc = overruledPerc;
                }
            }
        } else {
            if (parseInt(isReseller) === 1) {
                if (amount >= product.discountWholesaleMinAmount) {
                    discountPerc = product.discountWholesale;
                } else {
                    discountPerc = product.discount;
                }
            } else {
                if (amount >= product.discountMinAmount) {
                    discountPerc = product.discount;
                } else {
                    discountPerc = 0;
                }
            }
        }

        return discountPerc;
    }

    showPriceWholesale(product) {
        let priceReduced;

        const discountPerc = product.discountWholesale;
        const priceIncl = product.priceIncl;

        priceReduced = (100 - discountPerc)/100 * priceIncl;

        return priceReduced.toFixed(2);
    }

    showPriceDiscount(product) {
        let priceReduced;

        
        const discountPerc = product.discount;
        const priceIncl = product.priceIncl;

        priceReduced = (100 - discountPerc)/100 * priceIncl;

        return priceReduced.toFixed(2);
    }

    async getDiscountsOverruled(accountId, sku) {
        let discount = 0;
        try {
            const returnData = await discounts.getDiscountsOverruled(accountId, sku);
            runInAction(() => {
                discount = returnData.data[0];
                return discount;

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return discount;
    }
}

export default StoreDiscount;