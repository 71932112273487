import * as apifetch from './fetch.js';

export async function checkLogin(data) {
    const urlEndpoint = '/v2/accounts/login';

    const postData = data;
          
    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function resetPassword(data) {
    const urlEndpoint = '/v2/accounts/reset';

    const postData = data;
         
    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function checkVerification(data) {
    const urlEndpoint = '/v2/accounts/verify';

    const postData = data;
      
    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}

export async function checkVerificationCode(data) {
    const urlEndpoint = '/v2/accounts/verify-code';

    const postData = data;
       
    return apifetch.authFetch(urlEndpoint, 'POST', postData);
}