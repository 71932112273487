import React, { Component, Suspense } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "../../withRouter";
import { withTranslation } from 'react-i18next';

import * as formats from '../../utilities/formats';
import validate from '../../utilities/validation-rules';

import TextInput from '../../elements/form-input-text';
import CartRowExplanation from './cart-row-explanation';

const CartRow = inject("stores") (
    observer (
        class CartRow extends React.Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;
                this.urlPublic = process.env.REACT_APP_URL_API_PUBLIC;;

                this.storeAuth = this.props.stores.storeAuth;
                this.storeProducts = this.props.stores.storeProducts;
                this.storeCart = this.props.stores.storeCart;
                this.storeDiscount = this.props.stores.storeDiscount;

                this.state = {
                    price: this.props.item.priceIncl,
                    totalRow: 0,
                    formControls: { 
                        amount: {
                            value: this.props.item.amount,
                            valid: false,
                            validationRules: {
                                isInteger: true
                            }
                        }
                    }
                }
            }

            componentDidMount = async() => {
                const product = await this.storeProducts.getProduct(this.props.item.sku);

                this.setState({
                    product: product
                }, () => this.updateCart(this.props.item.amount))
            }
            
            componentDidUpdate = async(prevProps) => {
                if (prevProps.item.sku !== this.props.item.sku) {
                    const product = await this.storeProducts.getProduct(this.props.item.sku);

                    this.setState({
                        product: product
                    }, async() => await this.calcPriceRow())
                }
            }

            calcPriceRow = async() => {
                let total;
                const isReseller = this.storeAuth.user.isReseller;
                const accountId = this.storeAuth.user.id;

                let discount = await this.storeDiscount.calcDiscount(this.state.product, this.state.formControls.amount.value, accountId, isReseller);

                total = this.state.formControls.amount.value * this.props.item.priceIncl * (1 - discount/100);    

                
                this.setState({
                    discount: discount,
                    totalRow: total
                })

                return discount;
            }

            priceDiscount = (product) => {
                let  priceDiscount = this.props.item.priceIncl;
                
                if (parseInt(this.storeAuth.user.isReseller) === 1) {
                    priceDiscount = this.props.item.priceIncl * (1 - this.state.discount/100);
                } else {
                    this.state.discount > 0
                    ?   priceDiscount = product.priceIncl * (1 - this.state.discount/100)
                    :   priceDiscount = product.priceIncl;
                }  

                return priceDiscount;
            }

            gotoProduct = (sku) => {
                const url = "/product/" + sku;
                this.props.navigate(url);
            }

            handleChange = (name, value) => {   
                if (parseInt(value) === 0 || parseInt(value) === '') {
                    this.handleDelete();
                } else {
                    const updatedControls = {
                        ...this.state.formControls
                    };
                    const updatedFormElement = {
                        ...updatedControls[name]
                    };
                    updatedFormElement.value = value;
                    updatedFormElement.touched = true;
                    updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
            
                    updatedControls[name] = updatedFormElement;

                    if (updatedFormElement.valid) {
                        this.setState({
                            formControls: {
                                ...this.state.formControls,
                                [name]: {
                                    ...updatedControls[name],
                                    value
                                }
                            }
                        }, () => this.updateCart(value));
                    }
                } 
            }

            handleDelete = async() => {
                const result = await this.storeCart.deleteCartItem(this.props.item.sku);

                const accountId = this.storeAuth.user.userId;
                const cartId = await this.storeCart.getCartId(accountId);   
                if (cartId !== undefined) {            
                    const cart = await this.storeCart.getCartItems(cartId);
                }
            }

            updateCart = async(value) => {
                const discount = await this.calcPriceRow();

                this.storeCart.updateCart(this.props.item.sku, value, discount);
            }

            render() {
                return (                  
                    <div
                        className = "wrapper-cartitem"
                    >
                        <div
                            className = "cartitem cartitem__image"
                        >
                            <img 
                                src = {this.urlPublic + "/public/images/product/" + this.props.item.sku + ".jpg"} 
                                className = "--link"
                                onClick = {() => this.gotoProduct(this.props.item.sku)}
                            />
                        </div>
                        <div
                            className = "cartitem cartitem__data"
                        >
                            <div
                                className = "cartitem cartitem__data-row"
                            >
                                <div
                                    className = "cartitem cartitem__descr"
                                    dangerouslySetInnerHTML={{ __html: this.props.item.description}}
                                />
                                <div
                                    className = "cartitem cartitem__amount cartitem__amount--price"
                                >
                                    {this.state.discount > 0 &&
                                        <span
                                            className = "product__price--reduced"
                                        >
                                            {formats.displayPrice(this.props.item.priceIncl)}
                                        </span>
                                    }
                                    <span>
                                    {this.state.discount
                                        ?   formats.displayPrice(this.priceDiscount(this.state.product))
                                        :   formats.displayPrice(this.props.item.priceIncl)
                                    }
                                    </span>
                                </div>    
                                <div
                                    className = "cartitem cartitem__amount"
                                >
                                    {this.props.editable === true
                                        ?   <TextInput 
                                                name = {'amount'} 
                                                placeholder = {this.state.formControls['amount'].placeholder}
                                                value = {this.state.formControls.amount.value}
                                                valid = {true}
                                                onChange = {(e) => this.handleChange(e.target.name, e.target.value)}
                                                onBlur = {this.handleBlur}
                                                className = {"--int-small"}
                                            />
                                        :   this.props.item.amount
                                    }
                                    <div 
                                        onClick={() => this.handleDelete()}
                                        className="cartitem__delete --link"
                                    >
                                        <span className="icon icon--delete"></span>
                                    </div>
                                </div>
                                <div
                                    className = "cartitem cartitem__amount"
                                >
                                    {formats.displayPrice(this.state.totalRow)}
                                </div>
                            </div>
                            <CartRowExplanation
                                product = {this.state.product}
                                amount = {this.state.formControls['amount'].value}
                                discount = {this.state.discount}
                            />
                        </div>
                    </div>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(CartRow));
