import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import * as carts from '../communicator/carts';

class StoreCart {
    cartId = 0
    cart = []

    defaultCountryCode = 'SE'
    invoiceCountryCode = ''
    shippingCountryCode = ''

    cartUser = {
        firstname: '',
        middlename: '',
        lastname: '',
        email: '',
        phonenr: '',
        company: '',
        address: '',
        housenr: '',
        addition: '',
        zipcode: '',
        city : '',
        country: '',
        countryCode: ''
    }
    cartAddressShipping = {
        firstname: '',
        middlename: '',
        lastname: '',
        address: '',
        housenr: '',
        addition: '',
        zipcode: '',
        city : '',
        country: '',
        countryCode: ''
    }
    nrItems = 0;
    amount = 0;
    total = 0;
    
    constructor(storeRoot) {
        this.storeRoot = storeRoot;
        this.storeGeneral = this.storeRoot.storeGeneral;
        this.storeAuth = this.storeRoot.storeAuth;
    }

    resetCart = () => {
        this.deleteCart(this.cartId);

        this.cartId = 0;
        this.cart = []; 
        this.nrItems = 0;
        this.amount = 0;
        this.setLocalStorage(0);
    }

    getCartId = async(accountId) => {
        let result;
        let cartId;

        try {
            const returnData = await carts.getCartId(accountId);
            runInAction(() => {
                result = returnData.data;
                this.cartId = result[0].cartId;
                cartId = result[0].cartId;
                
                return cartId;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return cartId;
    }

    deleteCart = async(cartId) => {
        try {
            const returnData = await carts.deleteCart(cartId);
            runInAction(() => {

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    getCartItems = async(cartId) => {
        let cart;
        let id;

        if (cartId === 0) {
            if (localStorage.getItem('ABANDA_CARTID')) {
                const data = JSON.parse(localStorage.getItem('ABANDA_CARTID'));
                id = data;
            }
        } else {
            id = cartId;
        }

        try {
            const returnData = await carts.getCartItems(id);
            runInAction(() => {
                cart = returnData.data;
                this.cart = returnData.data;
                id = returnData.data[0].cartId;
                this.cartId = id;
                this.setLocalStorage(id);

                return cart;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return cart;
    }

    getCartAddresses = async() => {
        let result;
        const cartId = this.cartId;

        try {
            const returnData = await carts.getCartAddresses(cartId);
            runInAction(() => {
                result = returnData.data[0];

                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    async deleteCartItem(sku) {
        const cartId = this.cartId;

        try {
            const returnData = await carts.deleteCartItem(cartId, sku);
            runInAction(() => {
                this.cart = returnData.data;
                this.nrItems = this.calcNrItems;

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    getLocalStorageId() {
        let id = 0;

        if (localStorage.getItem('ABANDA_CARTID')) {
            const data = JSON.parse(localStorage.getItem('ABANDA_CARTID'));
            id = data;
        }

        return id;
    }

    setLocalStorage(cartId) {
        localStorage.setItem('ABANDA_CARTID', cartId)
    }

    updateCart = async(sku, value, discount) => {
        let cart;

        const cartId = this.cartId;

        const idx = this.cart
            .findIndex((item) => item.sku === sku); 
        this.cart[idx].amount = value;
        this.cart[idx].discount = discount;

        try {
            const returnData = await carts.updateCart(cartId, this.cart[idx]);
            runInAction(() => {
                this.cart = returnData.data;
                this.nrItems = this.calcNrItems;
                return cart;

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return cart;
    }

    updateCartUser(user) {
        this.cartUser = user;
    }

    updateCartUserFromAccount = (formControls) => {
        this.cartUser = {
            firstname: formControls.invoiceFirstname.value,
            middlename: formControls.invoiceMiddlename.value,
            lastname: formControls.invoiceMastname.value,
            email: formControls.invoiceEmail.value,
            phonenr: formControls.invoicePhonenr.value,
            company: formControls.invoiceCompany.value,
            address: formControls.invoiceAddress.value,
            housenr: formControls.invoiceHousenr.value,
            addition: formControls.invoiceAddition.value,
            zipcode: formControls.invoiceZipcode.value,
            city: formControls.invoiceCity.value,
            country: formControls.invoiceCountry_select.value,
            countryCode: formControls.invoiceCountry_select.value
        }
    }

    updateCartAddresses = async() => {
        const cartId = this.cartId;

        try {
            const returnData = await carts.updateCartAddresses(cartId, this.cartUser);
            runInAction(() => {
                //this.cart = returnData.data;
                this.nrItems = this.calcNrItems;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    updateCartAddressShipping = async(formControls) => {
        const cartId = this.cartId;

        this.cartAddressShipping = {
            firstname: formControls.shippingFirstname.value,
            middlename: formControls.shippingMiddlename.value,
            lastname: formControls.shippingLastname.value,
            address: formControls.shippingAddress.value,
            housenr: formControls.shippingHousenr.value,
            addition: formControls.shippingAddition.value,
            zipcode: formControls.shippingZipcode.value,
            city: formControls.shippingCity.value,
            country: formControls.shippingCountry_select.value,
            countryCode: formControls.shippingCountry_select.value
        }

        try {
            const returnData = await carts.updateCartAddressShipping(cartId, this.cartAddressShipping);
            runInAction(() => {

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    addToCart = async(product, price, reseller) => {
        const cartId = this.cartId;
        const accountId = this.storeAuth.user.userId;

        try {
            const returnData = await carts.addToCart(cartId, accountId, product, price, reseller);
            runInAction(() => {              
                this.cartId = returnData.data[0].cartId;
                this.cart = returnData.data;
                this.nrItems = this.calcNrItems;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    calcTotal = () => {
        let total = 0;
        let i;

        if (this.cart) {
            for (i=0; i < this.cart.length; i++){
                total = total + (this.cart[i].amount * this.cart[i].priceIncl * (1 - this.cart[i].discount / 100));
            }
        }

        this.total = total;

        return total;
    }

    get calcNrItems() {
        let total = 0;
        let i;

        if (this.cart) {
            for (i=0; i < this.cart.length; i++){
                total = total + this.cart[i].amount;
            }
        }

        return total;
    }

    saveCartData = async(email) => {
        const cartId = this.cartId;
        const accountId = this.storeAuth.user.userId;

        try {           
            returnData = await carts.saveCartData(accountId, cartId, email);

            runInAction(async() => {

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }   
    }

    saveCartTotals = async(subtotal, shipping, total, vat) => {
        const cartId = this.cartId;

        try {           
            returnData = await carts.saveCartTotals(cartId, subtotal, shipping, total, vat);

            runInAction(async() => {
                this.subtotal = subtotal;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }   
    }

    setInvoiceCountryCode = (value) => {
        this.invoiceCountryCode = value;
    }

    setShippingCountryCode = (value) => {
        this.shippingCountryCode = value;
    }
}

decorate(StoreCart, {
    amount: observable,
    nrItems: observable,
    cartAddressShipping: observable,
    cartUser: observable,
    invoiceCountryCode: observable,
    shippingCountryCode: observable,
    total: observable,
    saveCartTotals: action,
    setShippingCountryCode: action,
    updateCart: action,
    updateCartUser: action,
    updateCartAddresses: action,
    updateCartUserFromAccount: action
})

export default StoreCart;